.icon-links {
    height: 64px;
    width: 64px;
    margin: 16px;
}

.body-container {
    max-width: 100vw;
}

