/* src/components/Navbar.css */

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #333;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .navbar-links {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    font-size: small;
    padding-right: 20px;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    font-family: 'Press Start 2P', cursive;
  }
  
  .navbar-links a:hover {
    text-decoration: underline;
  }
  