.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  padding-top: 50px;
  background-color: #5384e4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-size: smaller;
}

p {
  font-size: x-small;
  line-height: 1.5;
  ;
}