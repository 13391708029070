main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    /* Optional: Ensures main takes at least the full viewport height */
}

.ContentContainer,
.tweet {
    width: 80%;
    /* Adjust width as needed */
    max-width: 800px;
    /* Optional: Limits the max width */
    margin: 20px 0;
    /* Optional: Adds vertical spacing between items */
}